import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Row, Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import {
  H2White,
} from './typography';

const StyledH2White = styled(H2White)`
  margin-bottom: 30px;

  @media (min-width: 64em) {
    margin-top: 69px;
    margin-bottom: 36px;
  }
`;

const StyledH2WhiteRight = styled(StyledH2White)`
  @media (min-width: 64em) {
    text-align: right;
  }
`;

const CompanyNav = () => (
  <Row>
    <Col lg={2} md={4} sm={4} xs={12}>
      <StyledH2White><AnchorLink to="/company/#team">our team</AnchorLink></StyledH2White>
    </Col>
    <Col lg={2} md={4} sm={4} xs={12} lgOffset={6}>
      <StyledH2WhiteRight><AnchorLink to="/company/#company">our company</AnchorLink></StyledH2WhiteRight>
    </Col>
    <Col lg={2} md={4} sm={4} xs={12}>
      <StyledH2WhiteRight><AnchorLink to="/company/#awards">our awards</AnchorLink></StyledH2WhiteRight>
    </Col>
  </Row>
);

export default CompanyNav;
