import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import BackgroundImage from 'gatsby-background-image';
import Carousel from 'react-multi-carousel';
import SEO from '../components/seo';
import Layout from '../components/layout';
import {
  H1White, H2White, PWhite, PSmallWhite,
} from '../components/typography';
import 'react-multi-carousel/lib/styles.css';
import CompanyNav from '../components/companyNav';
import Arrow from '../images/arrow-right.svg';

const responsive = {
  desktop: {
    breakpoint: { max: 6000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const StyledGrid = styled(Grid)`
  margin-bottom: 50px;
  max-width: 89rem;
  width: 100%;

  @media (min-width: 64em) {
    margin-bottom: 106px;
  }
`;

const StyledNavGrid = styled(StyledGrid)`
  margin-bottom: 0;

  @media (min-width: 64em) {
    margin-bottom: 0;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 35px;

  @media (min-width: 64em) {
    margin-bottom: 69px;
  }
`;

const StyledPWhite = styled(PWhite)`
  margin-bottom: 40px;
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 504px;
  margin-bottom: 50px;

  @media (min-width: 64em) {
    margin-bottom: 106px;
  }
`;

const StyledH1White = styled(H1White)`
  margin-bottom: 15px;

  @media (min-width: 64em) {
    margin-bottom: 73px;
  }
`;

const LI = styled.li`
  color: var(--white);
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1.5rem;
`;

const ResponsiveTable = styled.div`
  overflow: auto;
  width: 100%;
`;

const Table = styled.table`
  color: var(--white);
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1.5rem;
`;

const THCol1 = styled.th`
  width: 8.333333333333334%;
  text-align: left;
  padding-bottom: 25px;

  @media (max-width: 64em) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const THCol3 = styled.th`
  width: 25%;
  text-align: left;
  padding-bottom: 25px;

  @media (max-width: 64em) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const StyledH2WhiteAlt = styled(H2White)`
  margin-bottom: 40px;
`;

const StyledJobTitles = styled(H2White)`
  @media (max-width: 64em) {
    margin-top: 20px;
  }
`;

const StyledPSmallWhite = styled(PSmallWhite)`
  @media (max-width: 64em) {
    margin-bottom: 20px;
  }
`;

const TR = styled.tr`
  border-top: 1px solid var(--white);
`;

const TD = styled.td`
  padding-top: 13px;
  padding-bottom: 13px;

  @media (max-width: 64em) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  height: 367px;
`;

const ArrowRight = styled(Arrow)`
  height: 12px;
  fill: var(--white);
  margin-left: 8px;
`;

const StyledLink = styled(Link)`
  color: var(--white);
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.89px;
  line-height: 1.125rem;
  margin-bottom: 35px;
  display: block;

  & > p {
    &:hover,
    &:focus {
      color: var(--blue);
      transition: color 0.5s ease;
    }
  }

  &:hover,
  &:focus {
    & > svg {
      fill: var(--blue);
      transition: fill 0.5s ease;
    }
  }
`;

const StyledLinkTeam = styled(Link)`
  & > li {
    &:hover,
    &:focus {
      color: var(--blue);
      transition: color 0.5s ease;
    }
  }
`;

const StyledImg = styled(Img)`
  height: 504px;
`;

const StyledCustomArrowRight = styled(Arrow)`
  fill: var(--white);
  height: 43px;
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  right: calc(2.4% + 1px);

  &:hover,
  &:focus {
    fill: var(--blue);
    transition: fill 0.5s ease;
  }
`;

const StyledCustomArrowLeft = styled(Arrow)`
  fill: var(--white);
  height: 43px;
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  left: calc(2.4% + 1px);
  transform: rotate(-180deg);

  &:hover,
  &:focus {
    fill: var(--blue);
    transition: fill 0.5s ease;
  }
`;

const Company = ({ data, onClick }) => {
  const teamMemberHasProfile = data.allDatoCmsTeam.edges.filter(
    ({ node: teamMember }) => teamMember.hasprofile,
  );

  return (
    <Layout>
      <SEO title="Company" />
      <StyledNavGrid fluid>
        <CompanyNav />
      </StyledNavGrid>
      <SliderContainer>
        <Carousel
          responsive={responsive}
          customRightArrow={
            <StyledCustomArrowRight onClick={() => onClick()} />
          }
          customLeftArrow={<StyledCustomArrowLeft onClick={() => onClick()} />}
        >
          {teamMemberHasProfile.map(({ node: teamMember }) => (
            <Link to={`/team/${teamMember.slug}`} key={teamMember.id}>
              {teamMember.avatar && (
                <StyledImg
                  fluid={{ ...teamMember.avatar.fluid, aspectRatio: 1 }}
                  key={teamMember.id}
                />
              )}
            </Link>
          ))}
        </Carousel>
      </SliderContainer>
      <StyledGrid fluid>
        <Row>
          <Col lg={3} md={3} xs={12} lgOffset={1}>
            <StyledH1White id="team">our team</StyledH1White>
          </Col>
        </Row>
        <Row>
          <Col lg={2} md={2} xs={12} lgOffset={1}>
            <StyledJobTitles>directors</StyledJobTitles>
            <ul>
              {data.allDatoCmsTeam.edges.map(({ node: team }) => {
                if (team.title === 'director' && team.hasprofile) {
                  return (
                    <StyledLinkTeam to={`/team/${team.slug}`} key={team.id}>
                      <LI key={team.id}>{team.name}</LI>
                    </StyledLinkTeam>
                  );
                }
                if (team.title === 'director') {
                  return <LI key={team.id}>{team.name}</LI>;
                }

                return null;
              })}
            </ul>
          </Col>
          <Col lg={2} md={2} xs={12}>
            <StyledJobTitles>associates</StyledJobTitles>
            <ul>
              {data.allDatoCmsTeam.edges.map(({ node: team }) => {
                if (team.title === 'associate' && team.hasprofile) {
                  return (
                    <StyledLinkTeam to={`/team/${team.slug}`} key={team.id}>
                      <LI key={team.id}>{team.name}</LI>
                    </StyledLinkTeam>
                  );
                }
                if (team.title === 'associate') {
                  return <LI key={team.id}>{team.name}</LI>;
                }

                return null;
              })}
            </ul>
          </Col>
          <Col lg={2} md={2} xs={12}>
            <StyledJobTitles>architects</StyledJobTitles>
            <ul>
              {data.allDatoCmsTeam.edges.map(({ node: team }) => {
                if (team.title === 'architect') {
                  return <LI key={team.id}>{team.name}</LI>;
                }
                return null;
              })}
            </ul>
          </Col>
          <Col lg={2} md={2} xs={12}>
            <StyledJobTitles>team</StyledJobTitles>
            <ul>
              {data.allDatoCmsTeam.edges.map(({ node: team }) => {
                if (team.title === 'team') {
                  return <LI key={team.id}>{team.name}</LI>;
                }
                return null;
              })}
            </ul>
          </Col>
          <Col lg={2} md={2} xs={12}>
            <StyledJobTitles>office</StyledJobTitles>
            <ul>
              {data.allDatoCmsTeam.edges.map(({ node: team }) => {
                if (team.title === 'office') {
                  return <LI key={team.id}>{team.name}</LI>;
                }
                return null;
              })}
            </ul>
          </Col>
        </Row>
      </StyledGrid>
      <StyledGrid fluid>
        <Row>
          <Col lg={11} md={12} xs={12} lgOffset={1}>
            <StyledH1White id="company">our company</StyledH1White>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} xs={12} lgOffset={1}>
            <StyledH2WhiteAlt>
              {data.datoCmsCompanypage.companytitle}
            </StyledH2WhiteAlt>
            <StyledPWhite>
              {data.datoCmsCompanypage.companydescription}
            </StyledPWhite>
          </Col>
        </Row>
        <StyledRow>
          <Col lg={7} md={7} xs={12}>
            <StyledBackgroundImage
              fluid={data.datoCmsCompanypage.imagegrid7x5[0].fluid}
            />
            <StyledPSmallWhite>
              {data.datoCmsCompanypage.imagegrid7x5[0].title}
            </StyledPSmallWhite>
          </Col>
          <Col lg={5} md={5} xs={12}>
            <StyledBackgroundImage
              fluid={data.datoCmsCompanypage.imagegrid7x5[1].fluid}
            />
            <StyledPSmallWhite>
              {data.datoCmsCompanypage.imagegrid7x5[1].title}
            </StyledPSmallWhite>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col lg={6} md={6} xs={12}>
            <StyledBackgroundImage
              fluid={data.datoCmsCompanypage.imagegrid7.fluid}
              style={{ height: 493 }}
            />
            <StyledPSmallWhite>
              {data.datoCmsCompanypage.imagegrid7.title}
            </StyledPSmallWhite>
          </Col>
          <Col lg={5} md={5} xs={12}>
            <StyledH2WhiteAlt>
              {data.datoCmsCompanypage.masterplanningtitle}
            </StyledH2WhiteAlt>
            <StyledPWhite>
              {data.datoCmsCompanypage.masterplanningdescription}
            </StyledPWhite>
            <StyledLink to="/projects?filter=masterplanning">
              selected masterplanning projects
              <ArrowRight />
            </StyledLink>
            <StyledH2WhiteAlt>
              {data.datoCmsCompanypage.publicbuildingstitle}
            </StyledH2WhiteAlt>
            <StyledPWhite>
              {data.datoCmsCompanypage.publicbuildingsdescription}
            </StyledPWhite>
            <StyledLink to="/projects?filter=public buildings">
              selected public building projects
              <ArrowRight />
            </StyledLink>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col lg={6} md={6} xs={12} lgOffset={1}>
            <StyledH2WhiteAlt>
              {data.datoCmsCompanypage.educationteachingtitle}
            </StyledH2WhiteAlt>
            <StyledPWhite>
              {data.datoCmsCompanypage.educationteachingdescription}
            </StyledPWhite>
            <StyledLink to="/projects?filter=education%2Fscience">
              selected education and teaching projects
              <ArrowRight />
            </StyledLink>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col lg={5} md={5} xs={12}>
            <StyledBackgroundImage
              fluid={data.datoCmsCompanypage.imagegrid5x7[0].fluid}
            />
            <StyledPSmallWhite>
              {data.datoCmsCompanypage.imagegrid5x7[0].title}
            </StyledPSmallWhite>
          </Col>
          <Col lg={7} md={7} xs={12}>
            <StyledBackgroundImage
              fluid={data.datoCmsCompanypage.imagegrid5x7[1].fluid}
            />
            <StyledPSmallWhite>
              {data.datoCmsCompanypage.imagegrid5x7[1].title}
            </StyledPSmallWhite>
          </Col>
        </StyledRow>
      </StyledGrid>
      <StyledGrid fluid>
        <Row>
          <Col lg={3} md={3} xs={3} lgOffset={1}>
            <StyledH1White id="awards">our awards</StyledH1White>
          </Col>
        </Row>
        <Row>
          <Col lg={10} md={12} xs={12} lgOffset={1}>
            <ResponsiveTable>
              <Table>
                <thead>
                  <tr>
                    <THCol1>year</THCol1>
                    <THCol3>project</THCol3>
                    <THCol3>client</THCol3>
                    <THCol3>award</THCol3>
                  </tr>
                </thead>
                <tbody>
                  {data.allDatoCmsAward.edges.map(({ node: award }) => (
                    <TR key={award.id}>
                      <TD>{award.year}</TD>
                      <TD>
                        <Link to={`/projects/${award.project.slug}`}>
                          {award.project.title}
                        </Link>
                      </TD>
                      <TD>{award.client}</TD>
                      <TD>{award.award}</TD>
                    </TR>
                  ))}
                </tbody>
              </Table>
            </ResponsiveTable>
          </Col>
        </Row>
      </StyledGrid>
    </Layout>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query companyAwardQueryAndCompanyTeamQuery {
        allDatoCmsTeam {
          edges {
            node {
              id
              title
              name
              hasprofile
              slug
              avatar {
                fluid(maxHeight: 500) {
                  ...GatsbyDatoCmsSizes
                }
              }
            }
          }
        }
        allDatoCmsAward(sort: { fields: [year], order: DESC }) {
          edges {
            node {
              id
              year
              project {
                title
                slug
              }
              client
              award
            }
          }
        }
        datoCmsCompanypage {
          companytitle
          companydescription
          imagegrid7x5 {
            title
            fluid(maxHeight: 367) {
              ...GatsbyDatoCmsFluid
            }
          }
          imagegrid5x7 {
            title
            fluid(maxHeight: 367) {
              ...GatsbyDatoCmsFluid
            }
          }
          imagegrid7 {
            title
            fluid(maxHeight: 493) {
              ...GatsbyDatoCmsFluid
            }
          }
          masterplanningtitle
          masterplanningdescription
          publicbuildingstitle
          publicbuildingsdescription
          educationteachingtitle
          educationteachingdescription
        }
      }
    `}
    // eslint-disable-next-line react/jsx-props-no-spreading
    render={(data) => <Company data={data} {...props} />}
  />
);

Company.propTypes = {
  data: PropTypes.shape().isRequired,
  onClick: PropTypes.func,
};

Company.defaultProps = {
  onClick: () => {},
};
